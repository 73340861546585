import EventBus from '@/event-bus'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import { mapGetters } from 'vuex'

export default {
  mixins: [MixinIdeaRoles],

  mounted () {
    EventBus.$on('toggle-guide', (payLoad) => {
      this.guideVisible = payLoad.active
    })
  },

  data () {
    return {
      guideVisible: false
    }
  },

  watch: {
    async guideVisible (value) {
      if (value) {
        await this.$router.push({ path: this.$route.fullPath, query: { infoModalOpen: value } })

        return
      }

      await this.$router.replace({ query: null })
    }
  },

  computed: {
    ...mapGetters('user', [
      'isQuickTourCompleted'
    ])
  },

  methods: {
    openGuide (guideStep) {
      if (this.$store.state.idea.ideaGuide !== null && !this.$store.state.idea.ideaGuide[guideStep] && this.canEdit) {
        this.completeGuide(guideStep)
        setTimeout(() => {
          EventBus.$emit('open-guide')
          this.guideVisible = true
        }, 500)
      }
    },

    closeGuide () {
      this.guideVisible = false
      EventBus.$emit('close-guide')
    },

    completeGuide (guideName) {
      this.$http.put('guides/' + this.$store.state.idea.ideaGuide.id, { [guideName]: true })
        .then((response) => {
          if (response.status === 200) {
            this.$store.commit('idea/setGuide', { guide: response.data.payload.guide })
          }
        }).finally(() => {
          this.loading = false
        })
    },

    openGuideDialog (guideStepDialog) {
      if (this.$store.state.user.guideDialog !== null && !this.$store.state.user.guideDialog[guideStepDialog]) {
        this.completeGuideDialog(guideStepDialog)
        setTimeout(() => {
          this.guideVisible = true
        }, 500)
      }
    },

    completeGuideDialog (guideNameDialog) {
      this.$http.put(
        'guides-dialog/' + this.$store.state.user.guideDialog.id,
        { [guideNameDialog]: true }
      ).then((response) => {
        if (response.status === 200) {
          this.$store.commit('user/setGuideDialog', { guideDialog: response.data.payload.guideDialog })
        }
      }).finally(() => {
        this.loading = false
      })
    },

    async openQuickTour (quickTourName) {
      if (!this.isQuickTourCompleted(quickTourName)) {
        await this.$router.push({ path: this.$route.fullPath, query: { openQuickTour: true } })
        this.quickTourVisible = true
        this.isPageContentVisible = false
        this.completeQuickTour(quickTourName)
      }
    },

    completeQuickTour (quickTourName) {
      this.$http.put('quick-tour', { [quickTourName]: true })
        .then((response) => {
          this.$store.commit('user/setQuickTours', response.data.payload.quickTour.data)
        })
    },

    openTip (tipName) {
      if (this.canEdit && !this.$store.state.idea.tipOpen[tipName]) {
        this.$http.put('tip-open', { [tipName]: true })
          .then((response) => {
            this.$store.commit('idea/setTipOpen', response.data.payload.tipOpen.data)
          })
      }
    }
  }
}
