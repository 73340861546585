import { render, staticRenderFns } from "./AiSuggestionTag.vue?vue&type=template&id=45f66578&scoped=true"
import script from "./AiSuggestionTag.vue?vue&type=script&lang=js"
export * from "./AiSuggestionTag.vue?vue&type=script&lang=js"
import style0 from "./AiSuggestionTag.vue?vue&type=style&index=0&id=45f66578&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45f66578",
  null
  
)

export default component.exports